const toaster =
  document.querySelector('.toaster') || document.createElement('div');
toaster.ariaLive = 'polite';
toaster.classList.add('toaster');
if (!toaster.parentElement) {
  document.body.append(toaster);
}

export class PrideraiserToast extends HTMLElement {
  get variant(): string | null {
    return this.getAttribute('variant');
  }
  set variant(value: string | null) {
    if (value) {
      this.setAttribute('variant', value);
    } else {
      this.removeAttribute('variant');
    }
  }

  get timeout(): number | null {
    if (this.hasAttribute('timeout')) {
      return parseInt(this.getAttribute('timeout') || '0', 10);
    }
    return null;
  }
  set timeout(value: number | null) {
    if (value !== null) {
      this.setAttribute('timeout', `${value}`);
      this.addEventListener('animationend', this.animationEndListener);
    } else {
      this.removeAttribute('timeout');
      this.removeEventListener('animationend', this.animationEndListener);
    }
  }

  get dismissable(): boolean {
    return this.hasAttribute('dismissable');
  }
  set dismissable(value: boolean) {
    if (value) {
      if (this.querySelector('button')) return;
      this.setAttribute('dismissable', '');
      const button = document.createElement('button');
      button.type = 'button';
      button.classList.add('dismiss');
      button.dataset.variant = 'no-bg icon-only';
      button.innerHTML = '<span class="reader-only">Dismiss</span>';
      button.prepend(
        document.querySelector('.icon--xmark')?.cloneNode(true) ?? ''
      );
      this.append(button);
    } else {
      this.querySelector('button')?.remove();
      this.removeAttribute('dismissable');
    }
  }

  get group(): string | null {
    return this.getAttribute('group') || null;
  }
  set group(value: string | null) {
    if (value) {
      this.setAttribute('group', value);
      (
        [
          ...toaster.querySelectorAll(`pr-toast[group="${value}"]`),
        ] as PrideraiserToast[]
      ).map((t) => t.dismiss());
    } else {
      this.removeAttribute('group');
    }
  }

  get icon(): string | null {
    return this.getAttribute('icon') || null;
  }
  set icon(value: string | null | undefined) {
    if (value) {
      const icon = document.querySelector(`.icon--${value}`);
      this.querySelector('svg')?.remove();
      if (icon) {
        this.prepend(icon.cloneNode(true));
        this.setAttribute('icon', value);
      }
    } else {
      const icon = this.querySelector('svg');
      if (icon) {
        icon.remove();
      }
      this.removeAttribute('icon');
    }
  }

  get message(): string {
    return this.querySelector('span')?.textContent || '';
  }
  set message(value: string) {
    if (!this.querySelector('span')) {
      const span = document.createElement('span');
      this.append(span);
    }
    this.querySelector('span')!.textContent = value;
  }

  constructor() {
    super();
    requestAnimationFrame(() => {
      if (!this.closest('.toaster')) {
        this.role = 'alert';
        toaster.append(this);
      }
    });
  }

  connectedCallback() {
    this.addEventListener('animationend', (ev) => {
      switch (ev.animationName) {
        case '--pr--animation--toast-disappear':
          if (this.parentElement) {
            this.remove();
          }
          break;
      }
    });

    this.addEventListener('click', (ev) => {
      const { target } = ev;
      const button =
        target &&
        (target instanceof HTMLElement || target instanceof SVGElement) &&
        target.closest('button');
      if (button && button.classList.contains('dismiss')) {
        this.dismiss(true);
      }
    });
  }

  dismiss(manual: Boolean = false) {
    const classes = ['removing'];
    if (manual) {
      classes.push('removing--manual');
    }
    this.classList.add(...classes);
  }

  animationEndListener = (ev: AnimationEvent) => {
    switch (ev.animationName) {
      case '--pr--animation--toast-appear':
        setTimeout(() => {
          this.dismiss();
        }, this.timeout as number);
        break;
    }
  };
}

(() => {
  if (window.customElements.get('pr-toast')) return;

  window.customElements.define('pr-toast', PrideraiserToast);
})();
